<template>
  <div style="height: calc(100vh - 120px);">
    <listToolbar :toolbar="toolbar" @toolTap="toolTap" @addTap="addTap" />
    <listTable
      :list="list"
      :page="page"
      :loading="loading"
      :total="total"
      :pageSize="pageSize"
      :columns="columns"
      @page-change="pageChange" />
  </div>
  <el-drawer v-model="formDrawerVisible" size="30%" :title="typeForm === 'add'? '添加' : '修改'">
    <div v-if="formDrawerVisible" style="padding-left: 16px; padding-right: 16px;">
      <Form :columns="addColumns" :type="typeForm" @submit="submit" />
    </div>
  </el-drawer>
</template>

<script>
import listToolbar from '@/components/system/listToolbar.vue'
import { majorDel, majorList, majorSubmit } from '@/apis/common'
import listTable from '@/components/system/listTable.vue'
import Form from '@/components/system/form.vue'

export default {
  name: 'major',
  components: { Form, listTable, listToolbar },
  data() {
    return {
      info: JSON.parse(sessionStorage.getItem('user')) || {},
      toolbar: [
        {type: 'input', label: '名称搜索', model: 'name', value: ''},
        {type: 'button', label: '搜索', icon: 'el-icon-search', def: 'default', key: 'search'},
        {type: 'button', label: '添加', def: 'primary',key: 'add'}
      ],
      formData: {
        name: ''
      },
      page: 1,
      pageSize: 15,
      total: 0,
      loading: false,
      list: [],
      columns: [
        {label: '专业ID', prop: 'id', width: 100, type: 'text'},
        {label: '专业名字', prop: 'name', width: 200, type: 'text'},
        {label: '创建时间', prop: 'created_at', width: 150, type: 'text'},
        {label: '操作', width: 100, type: 'but', arr: [
            {type: 'primary', icon: 'el-icon-edit', label: '修改', onClick: (row) => this.modify(row)},
            {type: 'danger', icon: 'el-icon-delete', label: '删除', onClick: (row) => this.del(row)},
        ]}
      ],
      typeForm: 'add',
      addColumns: [
        {label: '专业名字', prop: 'name', type: 'input',value: ''}
      ],
      formDrawerVisible: false,
      addFormData: {}
    }
  },
  mounted() {
    this.getMajorList()
  },
  methods:{
    // 修改
    modify(row) {
      this.addFormData = row
      this.typeForm = 'edit'
      this.addColumns.forEach(item => {
        item.value = row[item.prop]
      })
      this.formDrawerVisible = true
    },
    del(e) {
      this.$confirm('是否删除该专业?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        majorDel({
          major_id: e.id,
          admin_id: this.info.id
        }).then(res => {
          this.getMajorList()
        })
      }).catch(() => {})
    },
    addTap() {
      this.addColumns.forEach(item => {
        item.value = ''
      })
      this.formDrawerVisible = true
      this.typeForm = 'add'
    },
    submit(e,n) {
      majorSubmit({
        admin_id: this.info.id,
        major_id: n === 'edit' ? this.addFormData.id : '',
        name: e.name
      }).then(res => {
        this.formDrawerVisible = false
        this.getMajorList()
      })
    },
    pageChange(e) {
      this.page = e
      this.getMajorList()
    },
    toolTap(data){
      this.formData = data
      this.page = 1
      this.getMajorList()
    },
    getMajorList() {
      this.loading = true
      majorList({
        ...this.formData,
        page: this.page,
        pagesize: this.pageSize,
        admin_id: this.info.id
      }).then(res => {
        this.total = res.data.data.count
        this.list = res.data.data.list.map(x => {
          x.is_show = x.is_show === 1
          return x
        })
        this.loading = false
      })
    }
  }
}
</script>

<style scoped>

</style>
